export const MonthlyCardData = [
	{
		headerOne: 'Free',
		moPrice: '0',
		iconName: 'CustomRocketLaunch',
		anPrice: '0',
		dolar: '$',
		mYTxt: '/mo',
		YrTxt: '/yr',
		color: 'danger',
		discrpition:
			'Discover the power of Standard reports, the ultimate solution for emerging enterprises in search of essential insights',
		item: [
			'2 simple reports / month',
			'Idea up to 1000 characters long',
			// 'Pdf report with watermark',
			'Choose your preferred language',
		],
		buttonTxt: 'Subscribe',
		buttonTxtTwo: 'Plan Selected',
		monthlyPlanId: '0',
		yearlyPlanId: '0',
		ideaLength: 1000,
	},
	{
		headerOne: 'Premium',
		moPrice: '19',
		anPrice: '209',
		iconName: 'AireplaneFill',
		dolar: '$',
		mYTxt: '/mo',
		YrTxt: '/yr',
		color: 'primary',
		discrpition:
			'Experience the benefits of Standard reports, combined with Advanced analysis to unlock unparalleled insights into your business potential',
		item: [
			'5 Simple or Standard reports/month',
			'2 Advanced reports/month',
			'Idea up to 2000 characters long',
			// 'Pdf report without watermark',
			'Choose your preferred language',
			'Early access to major new features',
			'Support team available',
		],
		buttonTxt: 'Subscribe',
		buttonTxtTwo: 'Subscribed',
		monthlyPlanId: 'price_1N7IeSDNF7RN4IDwjvDs5VMI',
		yearlyPlanId: 'price_1N7IdoDNF7RN4IDw4ZpeOKpT',
		ideaLength: 2000,
	},
	{
		headerOne: 'Pro',
		moPrice: '199',
		anPrice: '2,189',
		color: 'secondary',
		iconName: 'BriefcaseFill',
		dolar: '$',
		mYTxt: '/mo',
		YrTxt: '/yr',
		discrpition:
			'Unleash the power of Standard reports, the ultimate solution for emerging enterprises in search of essential insights',
		item: [
			'60 Simple or Standard reports/month',
			'12 Advanced reports/month',
			'Idea up to 4000 characters long',
			// 'Pdf report with custom watermark',
			'Choose your preferred language',
			'Early access to major new features',
			'Support team available',
			'API access (coming soon)',
		],
		buttonTxt: 'Subscribe',
		buttonTxtTwo: 'Subscribed',
		monthlyPlanId: 'price_1N6XqmDNF7RN4IDwkUzEGFkS',
		yearlyPlanId: 'price_1N6XqmDNF7RN4IDwJqLSkeIr',
		ideaLength: 4000,
	},
	{
		headerOne: 'Hub',
		moPrice: '999',
		anPrice: '10,989',
		iconName: 'BuildingsFill',
		dolar: '$',
		mYTxt: '/mo',
		YrTxt: '/yr',
		discrpition:
			'Embrace the all-encompassing collection of capabilities, complete with seamless integrations and API access for effortless utilization',
		item: [
			'500 Simple or Standard reports/month',
			'100 Advanced reports/month',
			'Idea up to 6000 characters long',
			// 'Pdf report with custom watermark',
			'Choose your preferred language',
			'Early access to major new features',
			'Support team available',
			'API access (coming soon)',
		],
		buttonTxt: 'Subscribe',
		buttonTxtTwo: 'Subscribed',
		monthlyPlanId: 'price_1N6XsqDNF7RN4IDwBQT5Dx4A',
		yearlyPlanId: 'price_1N6XsqDNF7RN4IDw7frNFJAx',
		ideaLength: 6000,
	},
	{
		headerOne: 'Custom',
		iconName: 'Send',
		color: 'info',
		discrpition:
			'Do you need more Advanced or Custom Reports? Do you need Vertical applications? Contact us!',
		buttonTxt: 'Request',
	},
];